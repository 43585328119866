.field_grid {
    display: grid;
    grid-template-columns: 3fr 3fr 1fr;
    place-items: center;
    gap: 0.5rem;
    width: 100%;
    margin: auto 0;
    }

    .wrapper {
        width: 100%;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        place-content: center;
        place-items: center;
        display: grid;
    }
    .action_wrapper {
        display: grid;
        margin-bottom: 1rem;

    }
    @media screen and (max-width: 768px) {
        .field_grid {
            width: 90%;
            margin: 1.75rem auto;
            }
}