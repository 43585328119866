
/* assistant-200 - hebrew */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/assistant-v16-hebrew-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/assistant-v16-hebrew-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/assistant-v16-hebrew-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/assistant-v16-hebrew-200.woff') format('woff'), /* Modern Browsers */
       url('./fonts/assistant-v16-hebrew-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/assistant-v16-hebrew-200.svg#Assistant') format('svg'); /* Legacy iOS */
}
/* assistant-300 - hebrew */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/assistant-v16-hebrew-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/assistant-v16-hebrew-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/assistant-v16-hebrew-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/assistant-v16-hebrew-300.woff') format('woff'), /* Modern Browsers */
       url('./fonts/assistant-v16-hebrew-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/assistant-v16-hebrew-300.svg#Assistant') format('svg'); /* Legacy iOS */
}
/* assistant-regular - hebrew */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/assistant-v16-hebrew-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/assistant-v16-hebrew-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/assistant-v16-hebrew-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/assistant-v16-hebrew-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/assistant-v16-hebrew-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/assistant-v16-hebrew-regular.svg#Assistant') format('svg'); /* Legacy iOS */
}
/* assistant-500 - hebrew */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/assistant-v16-hebrew-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/assistant-v16-hebrew-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/assistant-v16-hebrew-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/assistant-v16-hebrew-500.woff') format('woff'), /* Modern Browsers */
       url('./fonts/assistant-v16-hebrew-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/assistant-v16-hebrew-500.svg#Assistant') format('svg'); /* Legacy iOS */
}
/* assistant-600 - hebrew */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/assistant-v16-hebrew-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/assistant-v16-hebrew-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/assistant-v16-hebrew-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/assistant-v16-hebrew-600.woff') format('woff'), /* Modern Browsers */
       url('./fonts/assistant-v16-hebrew-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/assistant-v16-hebrew-600.svg#Assistant') format('svg'); /* Legacy iOS */
}
/* assistant-700 - hebrew */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/assistant-v16-hebrew-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/assistant-v16-hebrew-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/assistant-v16-hebrew-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/assistant-v16-hebrew-700.woff') format('woff'), /* Modern Browsers */
       url('./fonts/assistant-v16-hebrew-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/assistant-v16-hebrew-700.svg#Assistant') format('svg'); /* Legacy iOS */
}
/* assistant-800 - hebrew */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/assistant-v16-hebrew-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/assistant-v16-hebrew-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/assistant-v16-hebrew-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/assistant-v16-hebrew-800.woff') format('woff'), /* Modern Browsers */
       url('./fonts/assistant-v16-hebrew-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/assistant-v16-hebrew-800.svg#Assistant') format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form {
  display: grid;

  max-width: 308px;
  align-items: center;
  justify-content: center;
}

button {
  cursor: pointer;
  transition: 0.7s;
}

button:hover {
  opacity: 0.7;
  box-shadow: 1px 1px 1px black;
}


