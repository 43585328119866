.card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 15px 25px #0000001C;
    border-radius: 20px;

    margin: 0 auto;
    width: 100%;
    max-width: 702px;
}

.calc_grid {
    width: 98vw;
    z-index: 999;
    background: transparent url('assets/bg.png') 0% 0% no-repeat padding-box;
    background-size: 100vw 394px;
    overflow: hidden;
}

