* {
  text-align: center;
  font-family: 'Assistant',
    sans-serif !important;


}

:root {
  --animate-duration: 15s;
}

.main_layout {
  display: grid;
  place-content: center;
  align-self: center;
  grid-template-rows: minmax(90vh, auto) auto;
  justify-content: center;
}

.tip_grid {
  display: grid;
  grid-template-columns: 0.3fr 3fr;
  padding: 1rem;
}

.tip_grid p {
  display: grid;
  place-items: center;
}

ul li {
  text-align: right;
  font-size: 0.75rem;
}

.outlay_back_button {
  display: grid;
  place-items: center;
  place-content: center;
  margin-top: 0.75rem;
  grid-template-columns: 0.5fr 5fr;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.calc-bg {
  background: linear-gradient(55deg, #c5c6ff, #c3aaff, #ffffff, #bfddf1);
  background-size: 800% 800%;

  -webkit-animation: AnimationName 35s ease infinite;
  -moz-animation: AnimationName 35s ease infinite;
  animation: AnimationName 35s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 90%
  }

  50% {
    background-position: 100% 11%
  }

  100% {
    background-position: 0% 90%
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 90%
  }

  50% {
    background-position: 100% 11%
  }

  100% {
    background-position: 0% 90%
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 90%
  }

  50% {
    background-position: 100% 11%
  }

  100% {
    background-position: 0% 90%
  }
}


.MuiButtonBase-root {
  border-radius: 14px;
}

.MuiTextField-root {
  border-radius: 18px;
}

.MuiInputBase-input {
  border-radius: 18px;
}

.QontoStepIcon-completedIcon {

}

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #26abe2;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.blob.white {
  background: white;
  box-shadow: 0 0 0 0 #EE2F60;
  animation: pulse-white 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #EE2F60;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.btn-no-icon {
  background: #26ABE2 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #25546545;
  border: 1px solid #26ABE2;
  border-radius: 26px;
  opacity: 1;
  color: white;
  width: 151px;
  height: 48px;
  display: grid;

  align-items: center;
  place-items: center;
  align-content: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  padding: 0;
}

.btn {
  background: #26ABE2 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #25546545;
  border: 1px solid #26ABE2;
  border-radius: 26px;
  opacity: 1;
  color: white;
  width: 151px;
  height: 48px;
  display: grid;
  grid-template-columns: 24px 84px;
  align-items: center;
  place-items: center;
  align-content: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  padding: 0;
}

.btn_action_yes {
  background: #26ABE2 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #25546545;
  border: 1px solid #26ABE2;
  border-radius: 26px;
  opacity: 1;
  color: white;
  width: 151px;
  height: 48px;
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  place-items: center;
  align-content: center;
  justify-content: center;
  margin: 0 auto;
}

.btn_action_no {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 9px #2554654d;
  border: 1px solid #26ABE2;
  border-radius: 26px;
  opacity: 1;
  color: #0db2ee;
  font-weight: 300;
  width: 151px;
  height: 48px;
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  place-items: center;
  align-content: center;
  justify-content: center;
  margin: 0 auto;
}


.next {
  background: #26ABE2 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #25546545;
  border: 1px solid #26ABE2;
  border-radius: 26px;
  opacity: 1;
  color: white;
  width: 151px;
  height: 48px;
  display: grid;
  grid-template-columns: 84px 24px;

  align-items: center;
  place-items: center;
  align-content: center;
  justify-content: center;
  margin: 0 auto;

}

.back {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 9px #2554654d;
  border: 1px solid #26ABE2;
  border-radius: 26px;
  opacity: 1;
  color: #09719745;
  font-weight: 500;
  width: 151px;
  height: 48px;
  display: grid;
  grid-template-columns: 24px 84px;
  align-items: center;
  place-items: center;
  align-content: center;
  justify-content: center;
  margin: 0 auto;

}

.footer {}