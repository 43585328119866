.welcome_main_grid {
    display: grid;
    grid-template-columns: 2fr 6fr 2fr;
    width: 100vw;
    place-items: center;

}

.welcome_image {
    max-height: 805px;
    max-width: 38vw;
    align-self: baseline;
    justify-self: baseline;
}

.welcome_image_container {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.welcome {
    display: grid;
    padding: 0.5rem;
}

.logo {
    display: grid;
    place-items: center;
    place-content: center;
    margin: 0 auto;
    width: 22.5rem;
}

.welcome_action {
    display: grid;
    place-items: center;
    place-content: center;
    margin: 0 auto;
    width: 100%;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
}

.spaceman {
    display: grid;
    place-items: start;
    place-content: end;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    justify-content: right;
    margin-bottom: 35rem;

}

.stepper_container {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 3rem;

}

.spaceman_img {
    display: grid;
    align-self: baseline;
}

.welcome_text_highlight {

    font-size: 35px;
}

.welcome_text {
    font-weight: 300;
    line-height: 1.225rem;
}

@media screen and (max-width: 900px) {
    .welcome_text_highlight {
        font-size: 23px;
        padding: 0;
        width: 100%;
    }

    .welcome_text {
        font-size: 16px;
        font-weight: 300;
    }

    .spaceman {
        margin-top: -18rem;
        display: none;

    }

    .spaceman_img {
        width: 85px;
    }

    .welcome_image_container {
        display: none;
    }

    .welcome_main_grid {
        display: grid;
        grid-template-columns: 1fr;
        width: 100vw;
        place-items: center;

    }

    .welcome_action {
        display: flex;
        place-items: center;
        place-content: center;
        margin: 0 auto;
        width: 100%;
        flex-direction: column-reverse;

    }

}